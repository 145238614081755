document.addEventListener('DOMContentLoaded', function() {

    document.querySelectorAll('span.presentyear').forEach(function(span) {
        span.textContent = new Date().getFullYear();
    });

    document.querySelectorAll('span.cz_bustia').forEach(function(span) {
        span.innerHTML = 'caro' + 'lazer' + 'bone' + '&#64;h' + 'otmai' + 'l.com'
    });

    let links = document.querySelectorAll('a.cz_fb');
    links.forEach(function(link) {
        link.setAttribute('href', 'https://www.fac' + 'ebook.com/caro' + 'la.birulo');
    });

    links = document.querySelectorAll('a.cz_inst');
    links.forEach(function(link) {
        link.setAttribute('href', 'https://www.inst' + 'agram.com/car' + 'olazerbone');
    });

    links = document.querySelectorAll('a.cz_mail');
    links.forEach(function(link) {
        link.setAttribute('href', 'mailto:car' + 'olazerb' + 'one@ho' + 'tmail.com');
    });

    links = document.querySelectorAll('a.cz_whz');
    links.forEach(function(link) {
        link.setAttribute('href', 'https://w' + 'a.me/3933' + '91619784');
    });
})




                        // <ul class="home-background-slider__text-social">
                        //     <li><a class="cz_fb" target="_blank"><img class="logo" src="img/logo/fb-shadow.png"
                        //                 alt="facebook"></a></li>
                        //     <li><a class="cz_inst" target="_blank"><img class="logo" src="img/logo/insta-shadow.png"
                        //                 alt="intagram"></a></li>
                        //     <li><a class="cz_mail" target="_blank"><img class="logo" src="img/logo/mail-shadow.png"
                        //                 alt="mail"></a></li>
                        //     <li><a class="cz_whz" target="_blank"><img class="logo" src="img/logo/whz-shadow.png"
                        //                 alt="whatsapp"></a></li>
                        // </ul>
