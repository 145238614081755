document.addEventListener('DOMContentLoaded', function() {

    function getStyleSheet(title) {
        for (let i = 0; i < document.styleSheets.length; i++) {
            const sheet = document.styleSheets[i];
            if (sheet.title === title) {
                return sheet;
            }
        }
        return null;
    }

    function updateLanguageStyle(lang) {
        const stylesheet = getStyleSheet('language');
        if (!stylesheet) return;

        const rules = stylesheet.cssRules || stylesheet.rules;

        // Update the stylesheet rules
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (rule.selectorText) {
                const selectorLang = rule.selectorText.match(/:lang\((.+?)\)/)[1];
                if(selectorLang){
                    if (rule.selectorText.startsWith('span')) {
                        rule.style.display = selectorLang === lang ? 'inline' : 'none';
                    } else if (rule.selectorText.startsWith('div')) {
                        rule.style.display = selectorLang === lang ? 'block' : 'none';
                    }
                } else {
                    console.warn('language not set up')
                }
            }
        }

        // Update the lang attribute on the <html> element
        document.documentElement.setAttribute('lang', lang);

        changeMetaDescriptionAndKeywords(lang)
    }

    function addLanguageSwitchListener() {
        document.querySelectorAll('.language a').forEach(link => {
            link.addEventListener('click', function(event) {
                event.preventDefault();
                const langClass = this.className;
                const lang = langClass.split('_')[0];
                updateLanguageStyle(lang);
            });
        });
    }

    function detectBrowserLanguage() {
        // Get the user's preferred language (e.g., 'en', 'es', 'fr', etc.)
        const userLang = navigator.language
        // Get the base language (first two letters)
        return userLang.substring(0, 2);
    }

    function changeMetaDescriptionAndKeywords(lang) {
        const metaDescriptionElem = document.querySelector('meta[name="description"]');
        const metaKeywordsElem = document.querySelector('meta[name="keywords"]');
        if (metaDescriptionElem) {
            if(lang === 'it'){
                metaDescriptionElem.content = metaContent.description.it
            } else if(lang ==='es'){
                metaDescriptionElem.content = metaContent.description.es
            } else { // 'en' and others
                metaDescriptionElem.content = metaContent.description.en
            }
        } else {
            console.error('"description" meta element not found.');
        }
        if (metaKeywordsElem) {
            if(lang === 'it'){
                metaKeywordsElem.content = metaContent.keywords.it
            } else if(lang ==='es'){
                metaKeywordsElem.content = metaContent.keywords.es
            } else { // 'en' and others
                metaKeywordsElem.content = metaContent.keywords.en
            }
        } else {
            console.error('"keywords" meta element not found.');
        }
    }

    changeMetaDescriptionAndKeywords(detectBrowserLanguage())

    addLanguageSwitchListener();
})

const metaContent = {
    'description': {
        'en': 'Carola Zerbone official web site. Mural and Illustration artist. Interior design.',
        'es': 'Sitio web oficial de Carola Zerbone. Artista muralista e ilustradora. Diseño de interiores.',
        'it': 'Sito ufficiale di Carola Zerbone. Artista murale e illustratore. Decorazione d\'interni',
    },
    'keywords': {
        'en': 'Interior design, interior design, interior decoration, illustrator, muralist, art, artwork, illustration, graphic art, photography, cover design, advertising, architect, logos, portraits, travel notebooks, corporate image, graphic designer, merchandising design, product photography, Barcelona, Milan, Genoa',
        'es': 'Interiorismo, diseño de interiores, decoración de interiores, ilustrador, muralista, arte, obra de arte, ilustración, arte gráfico, fotografía, diseño de portadas, publicidad, arquitecto, logotipos, retratos, cuadernos de viaje, imagen corporativa, diseñador gráfico, diseño de merchandising, fotografía de producto, Barcelona, Milán, Génova',
        'it': 'Interior design, interior design, decorazione d\'interni, illustratore, muralista, arte, opera d\'arte, illustrazione, grafica, fotografia, progettazione di copertine, pubblicità, architetto, loghi, ritratti, quaderni di viaggio, immagine aziendale, grafico, progettazione di merchandising, fotografia di prodotto, Barcellona, Milano, Genova',
    }
}


